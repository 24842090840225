import { render, staticRenderFns } from "./mywarung_user_transation.vue?vue&type=template&id=5c0ddbd4&scoped=true"
import script from "./mywarung_user_transation.vue?vue&type=script&lang=js"
export * from "./mywarung_user_transation.vue?vue&type=script&lang=js"
import style0 from "./mywarung_user_transation.vue?vue&type=style&index=0&id=5c0ddbd4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0ddbd4",
  null
  
)

export default component.exports