<template>
  <section>
    <div id="mainModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal(false)"></button>
        <div class="uk-modal-header">
          <h4 class="uk-text-bold">{{ data.title }}</h4>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <div v-for="(body, bKey) in data.body" :key="bKey" class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto uk-text-bold">{{ bKey }}:</div>
              <div class="uk-width-expand uk-margin-small-left">{{ body }}</div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
            <button v-if="isLoading" class="uk-button uk-button-danger" type="button" disabled><div uk-spinner></div></button>
            <button v-else class="uk-button uk-button-danger uk-border-rounded" type="button" @click="confirmDelete">
              {{ data.confirmText || "Delete" }}
            </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            isLoading: false,
        };
    },
    mounted() {
        window.UIkit.modal('#mainModal').show();
    },
    methods: {
        hideModal(isRefresh) {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("hideModal", isRefresh);
        },
        confirmDelete() {
            window.UIkit.modal('#mainModal').$destroy(true);
            this.$emit("confirmDelete", this.$props.data._id);
        }
    }
};
</script>
