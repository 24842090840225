<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin uk-margin-remove-bottom">
            <div class="uk-flex uk-flex-left">
                <h3>Mitra Transaction Details</h3>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-close-large" style="color: black;" type="button" uk-close @click="$router.back()"></button>
            </div>
        </div>
        <div class="uk-margin uk-margin-remove-top">
          <p class="uk-margin-remove">Mitra transaction history for <b>{{ user_details.fullname }}</b> in <b>{{ user_details.warung }}</b></p>
          <p class="uk-margin-remove-top">
            Company <b>{{ user_details.company }}</b><span class="uk-margin-small-left uk-margin-small-right">|</span>
            <b>{{ user_details.office }}</b><span class="uk-margin-small-left uk-margin-small-right">|</span><b>{{ user_details.role }}</b>
          </p>
          <div class="uk-flex uk-flex-left">
              <div class="uk-inline">
                  <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                  <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'description')" type="text" placeholder="Search Transaction">
              </div>
          </div>
        </div>
        <div class="uk-card uk-card-default">
          <div class="uk-overflow-auto">
              <table class="uk-table uk-table-medium uk-table-striped">
                  <thead>
                      <tr class="uk-text-left">
                          <th>Date</th>
                          <th>Types of Goods</th>
                          <th>Name of Goods</th>
                          <th>Total Amount of Spending</th>
                          <th class="uk-width-auto uk-text-center">Action</th>
                      </tr>
                  </thead>
                  <loading-table v-if="isLoading" :colspan="7"/>
                  <tbody v-else-if="page_data.docs.length>0">
                      <tr v-for="(data, i) in page_data.docs" :key="i" class="uk-text-left">
                          <td><div class="one-line-text">{{ formatter.dateComplete(data.created_at) }}</div></td>
                          <td><div class="one-line-text">{{ data.warung_product_id.name }}</div></td>
                          <td><div class="one-line-text">{{ data.description }}</div></td>
                          <td><div class="one-line-text">Rp{{ formatter.thousandSeparator(data.amount) }}</div></td>
                          <td class="uk-text-center">
                              <button class="uk-button uk-button-small uk-button-default" type="button" >Actions</button>
                              <div uk-dropdown="mode: click">
                                  <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                      <template v-if="consist(user_cred.roles, ['admin-community', 'qwe123'])">
                                          <li><a @click="showModal(data)">Edit</a></li>
                                          <li class="uk-nav-divider"></li>
                                          <li><a @click="showModalDelete(data)">Delete</a></li>
                                      </template>
                                  </ul>
                              </div>
                          </td>
                      </tr>
                  </tbody>
                  <empty-table v-else :colspan="5"/>
              </table>
          </div>
          <pagination
              :total-data="page_data.totalDocs"
              :change-limit="changeLimit"
              :change-page="changePage"
          />
      </div>
      <EditTransactionModal v-if="showEditModal" :data="form" @hideModal="hideModal" />
      <ConfirmDeleteModal v-if="deleteData.isShow" :data="deleteData" @hideModal="hideModal" @confirmDelete="confirmDelete" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import EditTransactionModal from '@/components/modals/community/mywarung/edit_transaction';
import ConfirmDeleteModal from '@/components/globals/modals/confirm_delete';
import { getUserLogin } from '@/utils/auth';
import formatter from "@/utils/formatter";
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
  
export default {
    data(){
        return {
            user_cred: getUserLogin(),
            user_details: {},
            meta: {
                limit: 10,
                page: 1
            },
            form: {
                _id: null,
                created_at: '',
                warung_product_id: '',
                user_id: this.$route.params.user_id,
                description: '',
                amount: 0,
            },
            page_data: { docs: [], totalDocs: 0 },
            isLoading: true,
            isEdit: false,
            showEditModal: false,
            formatter,
            deleteData: {
                isShow: false,
                title: 'Are you sure to delete this transaction?',
                _id: null,
                body: {
                    "Date": null,
                    "Type of Goods": null,
                    "Name of Goods": null,
                    "Total Ampunt of Spending": null,
                }
            },
            dataUser: null,
            dataPartnership: null,
            userDetailLoad: true
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        EditTransactionModal,
        ConfirmDeleteModal
    },
    watch: {
        meta() {
            this.getHistoryTransaction();
        },
    },
    async mounted() {
        [this.dataUser, this.dataPartnership] = await Promise.all([
            this.getUserFullname({user_ids: [this.$route.params.user_id]}),
            this.getUserPartnership({user_ids: [this.$route.params.user_id]})
        ]);
        this.getHistoryTransaction();
    },
    methods: {
        ...mapActions({
            getMitraTransaction: 'warung/getMitraTransaction',
            deleteWarungTransaction: 'warung/deleteWarungTransaction',
            getUserFullname: 'internal_api/getUserFullname',
            getUserPartnership: 'internal_api/getUserPartnership',
        }),
        async getUserData(){
            this.userDetailLoad = true;
            if (this.dataUser && this.dataPartnership) {
                const [partner, user] = await Promise.all([
                    this.dataPartnership.result.find((data) => data.user_id.toString() === this.$route.params.user_id.toString()),
                    this.dataUser.result.find((data) => data._id.toString() === this.$route.params.user_id.toString()),
                ]);
                if (partner) {
                    this.user_details.company = partner.company_id.name ?? "-";
                    this.user_details.office = partner.company_office_id.name ?? "-";
                    this.user_details.role = partner.role_id.name ?? "-";
                } else {
                    this.user_details.company = "-";
                    this.user_details.office = "-";
                    this.user_details.role = "-";
                }
                if (user) {
                    this.user_details.fullname = user.fullname ?? "-";
                    this.user_details.email = user.email ?? "-";
                    this.user_details.phone_number = user.phone_number ?? "-";
                } else {
                    this.user_details.fullname = "-";
                    this.user_details.email = "-";
                    this.user_details.phone_number = "-";
                }
            }
            this.userDetailLoad = false;
        },
        async getHistoryTransaction(){
            this.isLoading = true;
            const data = await this.getMitraTransaction({
                id: { w_id: this.$route.params.warung_id, u_id: this.$route.params.user_id },
                meta: this.meta
            });
            this.page_data = data.result;
            this.user_details = data.detail;
            this.getUserData();
            this.isLoading = false;
        },
        async getDataDetailWarung(){
            const warung = await this.getDetailWarung(this.$route.params.warung_id);
            if (warung && warung.warung) {
                this.warung_name = warung.warung.name;
            }
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        resetForm() {
            this.form = {
                _id: null,
                created_at: '',
                warung_product_id: '',
                user_id: this.$route.params.user_id,
                description: '',
                amount: 0,
            };
        },
        showModal(data){
            if (data) {
                this.form = {
                    _id: data._id,
                    created_at: data.created_at,
                    warung_product_id: data.warung_product_id._id,
                    user_id: this.$route.params.user_id,
                    description: data.description,
                    amount: data.amount,
                };
            }
            this.showEditModal = true;
        },
        hideModal(isRefresh){
            if (isRefresh) this.getHistoryTransaction();
              
            this.showEditModal = false;
            this.deleteData.isShow = false;
            this.resetForm();
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        showModalDelete(data){
            this.deleteData._id = data._id;
            this.deleteData.body["Date"] = formatter.dateComplete(data.created_at);
            this.deleteData.body["Type of Goods"] = data.warung_product_id.name;
            this.deleteData.body["Name of Goods"] = data.description;
            this.deleteData.body["Total Ampunt of Spending"] = `Rp${formatter.thousandSeparator(data.amount)}`;
            
            this.deleteData.isShow = true;
        },
        async confirmDelete(id) {
            this.isLoading = true;
            const response = await this.deleteWarungTransaction({w_id: this.$route.params.warung_id, t_id: id});
            if (response && response.status === 'OK') {
                notificationSuccess('deleted!');
                this.hideModal(true);
            } else {
                this.isLoading = false;
                notificationDanger('Failed to delete!');
            }
        }
    }
};
</script>

<style scoped>
  .one-line-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
  }
</style>
  
